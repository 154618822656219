<template>
  <div id="demo-mode">
    <div class="fixed-header" v-if="TestMode">
      <h1 class="fixed-header-txt">
        <v-icon color="#000000"> mdi-information </v-icon> YOU'RE IN TEST MODE
      </h1>
    </div>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
export default {
  data() {
    return {
      TestMode: companyConfig.TestMode,
    };
  },
};
</script>

<style lang="scss">
#demo-mode {
  .fixed-header {
    width: 100%;
    position: fixed;
    color: #ffffff;
    background-color: #ff0000;
    top: 0;
    z-index: 99;
    height: 25px;
    text-align: center;
  }
  .fixed-header-txt {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>