<template>
  <div id="add-profession">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-card-title> </v-card-title>
            <v-card-text>
              <h6 class="text-danger">* indicates required field</h6>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="4">
                    <label class="text-danger">* Document Type</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DocumentTypeOptionsLoading"
                      :items="DocumentTypeOptions"
                      :rules="DocumentTypeRules"
                      v-model="DocumentType"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label class="text-danger">* Document No</label>
                    <v-text-field
                      v-model="ReferenceNo"
                      :rules="ReferenceNoRules"
                      placeholder="Reference No"
                      hint="Enter your Reference No"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      Upload Document here <br />
                      <small>(only jpg, png image files are accepted)</small>
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg"
                      placeholder="Upload Proof Image"
                      prepend-icon="mdi-card-bulleted-settings"
                      append-outer-icon="mdi-delete"
                      v-model="UploadedImage1"
                      @change="addImage(1)"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="deleteImage(1)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col align="center" cols="12" md="4">
                    <h6 class="mycard-text1 fsize-4">Image Edit Options</h6>
                    <br />
                    <v-btn-toggle>
                      <v-btn fab small @click.prevent="flip(true, false)">
                        <v-icon>mdi-swap-horizontal-bold</v-icon>
                      </v-btn>
                      <v-btn fab small @click.prevent="flip(false, true)">
                        <v-icon>mdi-swap-vertical-bold</v-icon>
                      </v-btn>
                      <v-btn fab small @click.prevent="rotate(90)">
                        <v-icon>mdi-rotate-right-variant</v-icon>
                      </v-btn>
                      <v-btn fab small @click.prevent="rotate(-90)">
                        <v-icon>mdi-rotate-left-variant</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                    <br />
                    <br />
                    <h6 class="mycard-text1 fsize-4">
                      Uploaded Image will Appear Here
                    </h6>
                    <br />
                    <cropper ref="cropper" :src="SelectedImage1.ImageUrl" />
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <!-- <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="font-size-h6 px-10 mr-3 white--text"
                    >
                      Close
                    </v-btn> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid1"
          @click.prevent="submitForm"
          :loading="SubmitFlag"
          elevation="30"
          shaped
          tile
          small
          color="#1db954"
          class="font-size-h6 px-10 mr-3 white--text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      pageTitle: "Add document",
      pageDescription: "Add a document",

      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      UploadFlag: false,

      rows: [],
      member: {},

      DocumentTypeRules: [(v) => !!v || "Document Type is required"],
      DocumentType: "",
      DocumentTypeOptions: [],
      DocumentTypeOptionsLoading: false,

      ReferenceNo: "",
      ReferenceNoRules: [(v) => !!v || "Reference No is required"],

      UploadedImage1: {},
      SelectedImage1: { src: null },
      image: {
        // src: "media/images/jci-logo.png",
        src: "",
        type: null,
      },
    };
  },
  computed: {},
  watch: {
    DocumentTypeOptions: function () {
      console.log("watch DocumentTypeOptions");
      this.LoadingFlag = false;
      this.DocumentTypeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      // this.$emit('Member', this.NewMember);
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getDocumentTypeOptions();
    },
    getDocumentTypeOptions() {
      console.log("getDocumentTypeOptions called");
      this.DocumentTypeOptionsLoading = true;
      var selectbox1_source = "DocumentType";
      var selectbox1_destination = "DocumentTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 13,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadedImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage1.src) {
            URL.revokeObjectURL(this.SelectedImage1.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage1 = {
            ImageName: file.name,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage1 = {
            ImageName: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadedImage1 = {};
          break;

        default:
          break;
      }
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      if (this.$refs.cropper.customImageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      var message = "";
      if (validate1) {
        this.UploadFlag = true;
        const formData = new FormData();
        formData.append("UserInterface", 1);
        formData.append("DocumentType", this.DocumentType);
        formData.append("ReferenceNo", this.ReferenceNo);
        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          var image = "";
          canvas.toBlob((blob) => {
            image = canvas.toDataURL();
            console.log("image=" + image);
            this.selectedImage = image;
            formData.append("UploadedFile1", blob, "logo.png");
            this.uploadImage(formData);
            // Perhaps you should add the setting appropriate file format here
            // this.closePrompt();
          }, "image/jpeg");
        } else {
          //   this.uploadImage(formData);
          message += "Kindly upload image. ";
          //   this.toast("error", message, true);
        }
      } else {
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        this.toast("error", message, true);
        // this.sweetAlert("error", message, false);
      }
    },
    uploadImage(upload) {
      console.log("uploadImage is called");

      var UploadFlag = this.UploadFlag;
      console.log("UploadFlag=" + UploadFlag);

      if (UploadFlag) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-document-details/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.resetForm();
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;
          });
      } else {
        var message = "";
        if (ProfilePicture == "") {
          message += "File is not selected";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.member = {};
  },
};
</script>
<style lang="scss">
#add-profession {
}
</style>