<template>
  <div>
    <v-overlay :value="LoadingFlag">
      <div class="text-center">
        <p v-if="LoadingText != ''">{{ LoadingText }}</p>
        <p v-else>Please wait, your request is processing...</p>
        <p></p>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "Overlay-Loading-Component",
  props: {
    LoadingFlag: {
      required: true,
    },
    LoadingText: {
      type: String,
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss">
</style>
