import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH, CHECK_PERMISSION, UPDATE_PAGE_PERMISSION } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

import './plugins/chartist';

// axios
import axios from 'axios'
Vue.prototype.$http = axios
Vue.prototype.CancelToken = axios.CancelToken;

// Session
import VueSession from 'vue-session'
var options = {
  persist: true
}
Vue.use(VueSession, options)

// VueMaskDirective
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

// vue-json-excel

import JsonExcel from 'vue-json-excel'
Vue.component("JsonExcel", JsonExcel);

// base-material-card

import MaterialCard from '@/view/components/MaterialCard.vue'
Vue.component("base-material-card", MaterialCard);

// base-material-stats-card

import MaterialStatsCard from '@/view/components/MaterialStatsCard.vue'
Vue.component("base-material-stats-card", MaterialStatsCard);

// base-material-chart-card

import MaterialChartCard from '@/view/components/MaterialChartCard.vue'
Vue.component("base-material-chart-card", MaterialChartCard);

import Footer from '@/view/components/Footer.vue'
Vue.component("my-footer", Footer);

import PdfDocumentFilePreview from '@/view/components/PdfDocumentFilePreview.vue'
Vue.component("pdf-document-file-preview", PdfDocumentFilePreview);

import LomInformation from '@/view/components/LomInformation.vue'
Vue.component("lom-information", LomInformation);

import LoginLeftColumn from '@/view/components/LoginLeftColumn.vue'
Vue.component("login-left-column", LoginLeftColumn);

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

Vue.use(VuePlyr, {
  plyr: {}
});

// tiptap-vuetify

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: 'md'
})

import VueTabulator from 'vue-tabulator';
Vue.use(VueTabulator);

import UploadProgress from '@/view/UploadProgress.vue'
Vue.component("upload-progress", UploadProgress);

import OverlayLoadingProgress from '@/view/components/OverlayLoadingProgress.vue';
Vue.component("overlay-loading-progress", OverlayLoadingProgress);

import PopupPageContent from "@/view/pages/PopupPageContent.vue";
Vue.component("popup-page-content", PopupPageContent);

import AddNewDocument from "@/view/pages/profile/my-profile/AddNewDocument";
Vue.component("add-new-member-document", AddNewDocument);

import DemoMode from "@/DemoMode.vue";
Vue.component("demo-mode", DemoMode);

import DatetimePicker from 'vuetify-datetime-picker';
Vue.use(DatetimePicker);

import VueTour from 'vue-tour';
import 'vue-tour/dist/vue-tour.css';
Vue.use(VueTour);

import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
Vue.use(Cropper);
Vue.use(CircleStencil);

// Require Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min.js')

// Require Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

// v-viewer

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

// lazy load for images

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

// CK Editor

import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor);

// API service init
// ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  console.log('from=' + from.path + ', to=' + to.path);

  if (to.path == "/login" || to.path == "/access-denied") {
    next();
  } else {
    Promise.all([store.dispatch(CHECK_PERMISSION, to)]).then((res) => {
      console.log('router CHECK_PERMISSION res=' + res);
      var permissionFlag = res ? true : false;
      console.log('permissionFlag=' + permissionFlag);
      if (!res) {
        console.log('no permission permissionFlag=' + permissionFlag);
        // router.push('/access-denied');
        next();
        // next('/access-denied');
      } else {
        console.log('permission granted permissionFlag=' + permissionFlag);
        Promise.all([store.dispatch(UPDATE_PAGE_PERMISSION, res)]).then((res) => {
          console.log('routerUPDATE_PAGE_PERMISSION res=' + res);
        });
        next();
      }
    });
  }
  window.scrollTo(0, 0);
  // next();

  // reset config to initial state
  // store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 100);
});


new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
