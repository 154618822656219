<template>
  <div style="display: flex; height: 100%; width: 100%">
    <img style="max-width: 100%; margin: 0" v-lazy="LoginLeftBgImage" />
  </div>
</template>

<script>
import common from "@/view/Common.vue";

export default {
  name: "LoginLeftColumn",
  mixins: [common],
  data() {
    return {
      LoginLeftBgImage: "",
      Image1: {},
    };
  },
  watch: {
    Image1: function () {
      console.log("watch Image1");
      var Image1 = this.Image1;
      var tr = Image1.SettingsValues;
      console.log({ tr });
      this.LoginLeftBgImage = tr.FilePath;
    },
  },
  beforeMount() {
    console.log("beforeMount called");
    var url2 = "api/app-settings-content";
    var condition2 = {
      UserInterface: 1,
      AppName: "members_portal",
      SettingsName: "login_left_bg",
    };
    this.getPageDetails("Image1", {}, url2, condition2);
  },
};
</script>
