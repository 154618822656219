import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";

Vue.use(Vuex);
Vue.use(axios);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile
  }
});
