import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import companyConfig from "@/company_config.json";
import axios from "axios";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const CHECK_PERMISSION = "checkPermission";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const UPDATE_TOKEN = "updateToken";
export const UPDATE_PAGE_PERMISSION = "updatePagePermission";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_TOKEN = "setToken";
export const SET_PAGE_PERMISSION = "setPagePermission";

const state = {
  errors: null,
  user: {},
  token: "",
  pagePermission: 0,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  userToken(state) {
    return state.token;
  },
  userPageAccess(state) {
    return state.pagePermission;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          console.log("Here LOGIN post returns", data);
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log("Here LOGIN errors" + JSON.stringify(response));
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          console.log("Here what REGISTER returns=" + JSON.stringify(data));
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CHECK_PERMISSION](context, to) {
    console.log("CHECK_PERMISSION store actions called");
    var getters = context.getters;
    // console.log("getters=" + JSON.stringify(getters));
    // console.log("to=" + JSON.stringify(to));

    var server_url = companyConfig.apiURL;
    var server_mac = companyConfig.MACAddress;
    var server_flag = companyConfig.Server;
    console.log(
      "server_url=" +
        server_url +
        ", server_mac=" +
        server_mac +
        ", server_flag=" +
        server_flag
    );

    var url = "api/permission";
    var api_url = server_url + url;
    // var token = this.$session.get("token");
    var token = getters.userToken;
    token = token == (null || undefined) ? 0 : token;
    console.log("url=" + url + ", api_url=" + api_url + ", token=" + token);

    if (token != "") {
      return new Promise(resolve => {

        var output = "";
        var flag = false;

        axios({
          url: api_url,
          method: "POST",
          data: {
            url: to.path
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        })
          .then(function(response) {
            console.log(
              "CHECK_PERMISSION response=" + JSON.stringify(response)
            );
            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);
            resolve(flag);
            // return flag;
          })
          .catch(function(error) {
            console.log("error=" + error);
            resolve(flag);
          });
      });
    } else {
      return false;
    }
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [UPDATE_TOKEN](context, payload) {
    const token = payload;
    context.commit(SET_TOKEN, token);
  },
  [UPDATE_PAGE_PERMISSION](context, payload) {
    console.log("UPDATE_PAGE_PERMISSION store actions called");
    const flag = payload;
    var getters = context.getters;
    var token = getters.userToken;
    console.log("flag=" + flag + ", token=" + token);
    var permission = 0;
    if (flag == true) {
      permission = 1;
    } else {
      permission = token == (null || undefined) ? 2 : 3;
    }
    console.log("permission=" + permission);
    context.commit(SET_PAGE_PERMISSION, permission);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    console.log("SET_ERROR error=" + error);
    state.errors = error;
  },
  [SET_TOKEN](state, token) {
    console.log("SET_TOKEN token=" + token);
    state.token = token;
  },
  [SET_PAGE_PERMISSION](state, permission) {
    console.log("SET_PAGE_PERMISSION=" + permission);
    state.pagePermission = permission;
  },
  [SET_AUTH](state, user) {
    console.log("SET_AUTH=" + JSON.stringify(user));
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    // JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
