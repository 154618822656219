<template>
  <div>
    <v-app-bar
      color="primary"
      dense
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-toolbar-title>
        {{ PageTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <iframe
      id="ifrm"
      :src="Documentpath"
      v-if="PreviewButtonFlag && Documentpath != ''"
      width="100%"
      height="500px"
    ></iframe>
    <v-card v-if="Documentpath == ''" width="100%" height="250px">
      <v-row wrap>
        <v-col cols="12" md="12" align="center">
          <h1 class="text-danger">File not found.</h1>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    PageTitle: {
      type: String,
      required: true,
    },
    PreviewButtonFlag: {
      type: Boolean,
      required: true,
    },
    Documentpath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
  },
  beforeMount() {},
};
</script>
